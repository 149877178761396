@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 0 0px;
  width: fit-content;
}

.logo {
  height: 40px;
  width: auto;

  @media #{$tablet} {
    width: auto;
    height: 30px;
  }

  @media #{$mobile} {
    width: auto;
    height: 25px;
  }
}
