@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.wrapper {
  background-color: $primary-variant-1;
}

.cta {
  width: 300px;

  @media #{$mobile} {
    width: 100%;
  }

  @media #{$tablet} {
    width: 100%;
  }
}

.title {
  margin: 0 auto 25px auto;
  text-align: center;
  font-size: 40px;

  @media #{$mobile} {
    width: 250px !important;
    font-size: 24px;
    margin-bottom: 15px !important;
  }

  @media #{$tablet} {
    font-size: 32px;
  }
}

.subtitle {
  font-size: 24px;
  max-width: 700px !important;
  margin: 0 auto 60px auto;
  text-align: center;

  @media #{$mobile} {
    font-size: 14px;
    width: min(90%, 280px) !important;
    margin: 0 auto 40px auto;
  }

  @media #{$tablet} {
    width: 250px !important;
    font-size: 14px;
  }
}

.container {
  padding: 70px 100px 0px;

  @media #{$mobile} {
    padding: 40px 50px 0px;
  }
}

.includes {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.leftPanel {
  display: flex;
  flex-direction: column;
  gap: 75px;

  @media #{$tablet} {
    justify-content: center;
    flex-direction: row;
  }
}

.rightPanel {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  @media #{$responsive} {
    display: none;
  }
}

.service {
  align-items: center;
  gap: 15px;
  background-color: $supplemental-variant-2;
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-radius: 20px;
  flex: 1 0 40%;

  @media #{$responsive} {
    height: 335px;
    flex: unset;
    width: 240px;
  }
}

.category {
  font-size: 12px;
}

.sTitle {
  font-size: 24px;
}

.sDescription {
  font-size: 14px;
}

.swiperWrapper {
  display: none;
  width: 100%;

  @media #{$responsive} {
    display: block;
  }
}
