@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  align-items: center;
  display: flex;
}

%chevron {
  fill: $gray75;
  font-size: 35px;

  @media #{$mobile} {
    font-size: 25px;
  }
}

.button {
  padding: 0 !important;
}

.chevronLeft {
  @extend %chevron;

  margin-right: 15px;

  @media #{$responsive} {
    margin-right: 0;
  }
}

.chevronRight {
  @extend %chevron;

  margin-left: 15px;

  @media #{$responsive} {
    margin-left: 0;
  }
}

.child {
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.childContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.carousel {
  position: relative;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.item {
  overflow: hidden;
  position: absolute;
}
