@import "src/theme/media.scss";
@import "src/theme/colors.scss";

.wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 960px;
  justify-content: center;
  position: relative;

  @media #{$tablet} {
    padding-top: 150px;
    justify-content: unset;
    height: 540px;
  }

  @media #{$mobile} {
    padding-top: 150px;
    justify-content: unset;
    height: fit-content;
  }
}

.background {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;

  @media #{$mobile} {
    display: none;
  }
}

.mobileBackground {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;

  @media #{$mobile} {
    display: block;
  }
}

.title {
  font-size: 56px;
  width: 750px !important;
  margin-bottom: 15px !important;

  @media #{$tablet} {
    width: min(90%, 600px) !important;
    font-size: 32px;
  }

  @media #{$mobile} {
    width: min(90%, 300px) !important;
    font-size: 32px;
  }
}

.subtitle {
  font-size: 24px;
  width: 650px !important;

  @media #{$tablet} {
    width: min(90%, 330px) !important;
    font-size: 14px;
  }

  @media #{$mobile} {
    width: min(90%, 250px) !important;
    font-size: 14px;
  }
}

.cta {
  margin-top: 70px !important;

  @media #{$mobile} {
    margin-top: 150px !important;
  }

  @media #{$tablet} {
    margin-top: 25px !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
  padding: 0 75px;
  z-index: 1;

  @media #{$desktop} {
    padding-top: 0px;
  }

  @media #{$tablet} {
    padding: 0;
  }

  @media #{$mobile} {
    flex-direction: column;
    padding: 0 15px 30px;
  }
}
