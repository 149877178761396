@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.wrapper {
  background-color: $primary-variant-1;
}

.container {
  padding: 90px 100px;

  @media #{$tablet} {
    padding: 36px 20px;
  }

  @media #{$mobile} {
    padding: 40px 0;
  }
}

.title {
  font-size: 48px;

  @media #{$responsive} {
    font-size: 32px;
  }
}

.subtitle {
  font-size: 28px;
  margin-top: 25px !important;
  width: 800px !important;

  @media #{$tablet} {
    width: 470px !important;
    font-size: 14px;
  }

  @media #{$mobile} {
    font-size: 14px;
    width: min(300px, 90%) !important;
  }
}

.mobileSteps {
  margin-top: 40px;
}

.desktopSteps {
  margin-top: 75px;
  margin-left: auto;
  margin-right: auto;

  @media #{$tablet} {
    margin-top: 50px;
  }
}

.steps {
  display: flex;
  gap: 100px;

  @media #{$tablet} {
    gap: 60px;
  }
}

.line {
  width: 100px;
  position: absolute;
  top: 160px;
  right: 0;
  transform: translateX(100%);

  @media #{$tablet} {
    top: 120px;
    width: 60px;
  }
}

.image {
  border-radius: 20px;

  @media #{$tablet} {
    border-radius: 40px;
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;

  @media #{$tablet} {
    max-width: 190px;
  }
}

.callout {
  display: flex;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  padding: 35px 0;

  @media #{$tablet} {
    padding: 20px 0;
  }
}

.calloutLabel {
  font-size: 16px;
  width: 200px !important;

  @media #{$tablet} {
    font-size: 12px;
  }
}

.calloutArrow {
  position: absolute;
  right: -10px;
  top: 45px;
  width: 15px;

  @media #{$tablet} {
    top: 30px;
    right: 10px;
  }
}

.stepNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $white;
  width: 42px;
  height: 42px;
  position: relative;
  margin: -21px 0 25px;

  & * {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    position: absolute;
    padding-bottom: 3px;

    @media #{$responsive} {
      font-size: 24px;
    }
  }

  @media #{$responsive} {
    margin: -17px 0 20px;
    width: 34px;
    height: 34px;
  }
}

.stepTitle {
  width: 250px !important;
  font-size: 32px;
  margin-bottom: 25px !important;

  @media #{$tablet} {
    font-size: 20px;
    width: 90% !important;
  }

  @media #{$mobile} {
    width: 80% !important;
    font-size: 24px;
  }
}

.stepDescription {
  font-size: 20px;

  @media #{$tablet} {
    font-size: 15px;
  }

  @media #{$mobile} {
    font-size: 14px;
  }
}
