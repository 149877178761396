@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.wrapper {
  background-color: #f2f1eb;
}

.container {
  position: relative;
  overflow: visible;
  padding: 0 100px 80px;

  @media #{$tablet} {
    width: 740px !important;
    padding: 60px;
  }

  @media #{$mobile} {
    padding: 50px 30px;
  }
}

.stars {
  margin-top: 15px;
  display: flex;
  gap: 10px;

  margin: 15px auto;

  & svg {
    height: 30px;
    width: 30px;
    fill: $primary-variant-1;
  }

  @media #{$tablet} {
    margin: 15px auto 25px;

    & svg {
      height: 25px;
      width: 25px;
    }
  }

  @media #{$mobile} {
    margin: 15px auto 25px;

    & svg {
      height: 20px;
      width: 20px;
    }
  }
}

.googleLabel {
  font-size: 21px;

  @media #{$tablet} {
    font-size: 16px;
  }

  @media #{$mobile} {
    font-size: 15px;
  }
}

.reviewers {
  display: flex;
  justify-content: space-between;

  @media #{$tablet} {
    gap: unset;
    justify-content: space-between;
  }

  @media #{$mobile} {
    display: none;
  }
}

.chevron {
  fill: $primary-variant-1;
  font-size: 20px;
}

.reviewer {
  font-size: 26px;

  @media #{$tablet} {
    font-size: 16px;
  }

  @media #{$mobile} {
    font-size: 18px;
  }
}

.mobileReviewers {
  display: none;
  margin: auto;

  @media #{$mobile} {
    display: block;
  }
}

.location {
  font-size: 17px;

  @media #{$tablet} {
    font-size: 9px;
  }

  @media #{$mobile} {
    font-size: 11px;
  }
}

.reviewerInfo {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  width: 240px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }

  @media #{$mobile} {
    width: 100%;
  }
}

.selectedReviewer {
  opacity: 1;
}

.selectedComment {
  opacity: 1 !important;
}

.comment {
  transition: all 0.7s ease-in;
  opacity: 0;
  width: 900px;
  padding: 0 30px;
  min-width: 900px;
  display: flex;

  @media #{$tablet} {
    min-width: 600px;
    width: 600px;
  }

  @media #{$mobile} {
    padding: 0 30px;
    min-width: 100%;
    width: 100%;
  }

  & * {
    font-size: 29px;

    @media #{$tablet} {
      font-size: 17px;
    }

    @media #{$mobile} {
      font-size: 15px;
    }
  }
}

.comments {
  display: flex;

  @media #{$mobile} {
    align-items: center;
  }
}

.commentsContainer {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  width: 900px;
  margin-bottom: 50px;

  @media #{$tablet} {
    width: 600px;
  }

  @media #{$mobile} {
    width: 100%;
  }
}

.leftButton {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.rightButton {
  z-index: 2;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
