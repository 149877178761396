@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.wrapper {
  background-color: $primary-variant-1;
}

.container {
  padding: 20px 70px 80px;

  @media #{$tablet} {
    padding: 0 0 100px;
  }

  @media #{$mobile} {
    padding: 0 25px 90px;
  }
}

.title {
  font-size: 21px;
  margin-bottom: 50px !important;
  opacity: 0.5;

  @media #{$tablet} {
    margin-bottom: 30px !important;
    font-size: 16px;
  }

  @media #{$mobile} {
    width: min(330px, 100%) !important;
    margin-bottom: 20px !important;
    font-size: 15px;
  }
}

.content {
  display: flex;

  @media #{$tablet} {
    margin: auto;
    gap: 30px;
  }

  @media #{$mobile} {
    flex-direction: column-reverse;
  }
}

%panel {
  flex: 1;

  @media #{$tablet} {
    flex: unset;
    width: fit-content;
  }
}

.leftPanel {
  @extend %panel;
  display: flex;
  align-items: center;
  justify-content: center;

  @media #{$responsive} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media #{$mobile} {
    display: none;
  }

  img {
    width: calc(100% + 120px);
    height: auto;
    margin-right: -40px;
  }
}

.rightPanel {
  @extend %panel;
}

.membership {
  width: 540px;
  border-radius: 45px;
  background-color: $light-gray;
  padding: 60px 50px;
  margin: auto;

  @media #{$tablet} {
    width: 330px;
    border-radius: 30px;
    padding: 30px;
  }

  @media #{$mobile} {
    width: min(330px, 100%);
    padding: 30px 25px;
  }
}

.cta {
  width: 100%;
}

.membershipHeader {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 5px;
}

.priceText {
  font-size: 14px;

  @media #{$responsive} {
    font-size: 11px;
  }

  @media #{$mobile} {
    width: 100%;
    text-align: center;
  }
}

.price {
  gap: 10px;
  align-items: flex-end;
  display: flex;
  width: 100%;
  padding-bottom: 5px;

  @media #{$mobile} {
    gap: 5px;
    text-align: center;
    justify-content: center;
    padding-bottom: 15px;
  }

  .priceLabel {
    font-size: 54px;
  
    @media #{$mobile} {
      font-size: 36px !important;
    }
  }
  
  .monthLabel {
    font-size: 24px;
    margin-bottom: 4px;
  
    @media #{$mobile} {
      font-size: 18px !important;
      margin-bottom: 2px;
    }
  }
}

.items {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 30px 0 45px;

  @media #{$responsive} {
    padding: 15px 0 45px;
  }
}

.item {
  display: flex;
  align-items: flex-start;
  gap: 20px;

  & svg {
    font-size: 20px;
    fill: $primary-variant-1;
  }
}

.itemLabel {
  font-size: 20px;

  @media #{$responsive} {
    font-size: 14px;
  }
}

.check {
  margin-top: 3px;
}
