@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.wrapper {
  background-color: #f2f1eb;
}

.container {
  align-items: center;
  padding: 80px 0;
  gap: 40px;

  @media #{$mobile} {
    padding: 50px 15px;
  }

  .title {
    @media #{$responsive} {
      font-size: 32px !important;
    }
  
    @media #{$mobile} {
      font-size: 27px !important;
    }
  }
}

.table {
  position: relative;
  border-spacing: 0;

  @media #{$tablet} {
    font-size: 14px;
  }

  @media #{$mobile} {
    font-size: 11px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 80px);
    height: calc(100% - 80px);
    background-color: #fff;
    border-radius: 20px;

    @media #{$mobile} {
      height: calc(100% - 40px);
    }
  }

  table {
    position: relative;
    border-collapse: collapse;
  }

  thead {
    td {
      .label {
        padding: 60px 20px 10px;

        @media #{$mobile} {
          padding-top: 40px;
          font-size: 14px !important;

          * {
            font-size: inherit !important;
          }
        }
      }

      &:last-child {
        border-radius: 20px 20px 0 0;
      }
    }
  }

  tbody {
    tr {
      td {
        border-top: 1px solid black;
      }

      &:last-child {
        td {
          .label {
            padding-bottom: 65px;

            @media #{$mobile} {
              padding-bottom: 45px;
            }
          }

          &:last-child {
            border-radius: 0 0 20px 20px;
          }
        }
      }
    }
  }

  td {
    border: 0;
    padding: 0;

    &:first-child {
      .label {
        padding-left: 0;
      }
    }

    &:not(:first-child) {
      text-align: center;
    }

    &:last-child {
      color: white;
      background: $light-primary-1;
    }
  }

  .label {
    padding: 20px;

    svg {
      width: 150px;

      * {
        fill: white;
      }

      @media #{$mobile} {
        width: 40px;
      }
    }
  }

  .logo {
    @media #{$responsive} {
      display: none;
    }
  }

  .logomark {
    @media #{$desktop} {
      display: none;
    }
  }
}
