@import "src/theme/media.scss";
@import "src/theme/colors.scss";

.value {
  display: flex;
  gap: 5px;
}

.metric {
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media #{$mobile} {
    width: 100%;
  }

  & + .metric {
    &:not(:first-child) {
      padding-left: 20px;
    }

    @media #{$mobile} {
      border-left: unset;

      &:not(:first-child) {
        padding-top: 20px;
        padding-left: unset;
      }
    }
  }
}

.postfix {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
