@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  padding: 80px 130px;

  @media #{$mobile} {
    padding: 40px;
  }
}

.wrapper {
  background-color: $light-gray;
}

%panel {
  display: flex;
  flex-direction: column;
}

.leftPanel {
  @extend %panel;

  justify-content: center;
  align-items: flex-start;

  flex: 1;

  @media #{$tablet} {
    flex: unset;
    width: fit-content;
  }

  @media #{$mobile} {
    align-items: center;
  }
}

.rightPanel {
  @extend %panel;

  align-items: center;
  justify-content: center;
}

.partnersNetwork {
  font-size: 13px;
  margin-bottom: 10px !important;

  @media #{$mobile} {
    text-align: center !important;
    width: 100% !important;
  }
}

.titleWrapper {
  @media #{$mobile} {
    width: 100% !important;
  }
}

.title {
  font-size: 40px;

  @media #{$responsive} {
    font-size: 32px;
  }

  @media #{$mobile} {
    font-size: 32px;
    text-align: center !important;
    width: 100% !important;
  }
}

.subtitle {
  width: 350px !important;
  font-size: 18px;

  @media #{$tablet} {
    font-size: 14px;
    width: 240px !important;
  }

  @media #{$mobile} {
    margin: 0 auto !important;
    font-size: 14px;
    text-align: center !important;
    width: min(300px, 100%) !important;
  }
}

.subtitleWrapper {
  margin-top: 25px !important;
  margin-bottom: 35px !important;

  @media #{$responsive} {
    margin-bottom: 40px !important;
  }
}

.top {
  display: flex;
  width: 100%;

  @media #{$tablet} {
    justify-content: center;
    gap: 60px;
  }

  @media #{$mobile} {
    align-items: center;
    flex-direction: column;
    gap: 60px;
  }
}

.bottom {
  justify-content: center;
  display: flex;
  gap: 55px;
  margin-top: 100px;

  @media #{$responsive} {
    margin-top: 50px;
  }

  @media #{$mobile} {
    gap: unset;
    justify-content: space-between;
  }
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 180px;

  @media #{$mobile} {
    width: 90px;
  }
}

.serviceTitle {
  font-size: 20px;
  margin-top: 35px !important;

  @media #{$mobile} {
    font-size: 12px;
    margin-top: 20px !important;
  }
}

.image {
  position: relative;
  height: 80px;
  width: 80px;

  @media #{$tablet} {
    height: 60px;
    width: 60px;
  }

  @media #{$mobile} {
    height: 45px;
    width: 45px;
  }
}

.pricing {
  font-size: 40px;
  margin-bottom: 35px;

  @media #{$tablet} {
    font-size: 32px;
  }

  @media #{$mobile} {
    font-size: 32px;
  }
}

.membershipName {
  margin-bottom: 0 !important;
  font-size: 14px;

  @media #{$responsive} {
    font-size: 11px;
  }
}
