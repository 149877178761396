@import "src/theme/colors.scss";

.container {
  background-color: $white;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 25px;
}

.handle {
  background-color: $gray50;
  border-radius: 50px;
  cursor: pointer;
  height: 10px;
  margin-bottom: 15px;
  position: absolute;
  width: calc(100% - 50px);
  left: 25px;
  top: 7px;
}

.children {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > div {
    padding-top: 25px;
  }
}

.header {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > * {
    user-select: none;
  }
}

.icon {
  color: $black;
  font-size: 25px;
}
