@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.title {
  width: 750px !important;
  font-size: 40px;
  margin-bottom: 25px !important;

  @media #{$tablet} {
    width: 600px !important;
    font-size: 32px;
    margin-bottom: 15px !important;
  }

  @media #{$mobile} {
    width: 250px !important;
    font-size: 24px;
    margin-bottom: 15px !important;
  }
}

.subtitle {
  font-size: 24px;
  width: 700px !important;
  margin-bottom: 60px !important;

  @media #{$tablet} {
    font-size: 14px;
    width: min(90%, 450px) !important;
    margin-bottom: 20px !important;
  }

  @media #{$mobile} {
    font-size: 14px;
    width: min(90%, 250px) !important;
    margin-bottom: 20px !important;
  }
}

.wrapper {
  background-color: $light-gray;
}

.container {
  align-items: center;
  padding: 65px 15px;

  @media #{$tablet} {
    padding: 50px 15px;
  }

  @media #{$mobile} {
    padding: 30px;
  }
}

.carrouselItemWrapper {
  padding: 5px;
}

.carrouselItem {
  padding: 10px 20px;
  align-items: center;
  display: flex;
  border-radius: 20px;
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  width: 470px;

  @media #{$tablet} {
    font-size: 14px;
    width: calc(300px - 10px);
  }

  @media #{$mobile} {
    padding: 15px;
    width: calc(min(350px, 100vw - 40px) - 10px);
  }
}

.divider {
  position: absolute;
  top: 40px;
  border-bottom: 3px solid $gray75;
  width: 100%;
}

.helpTitle {
  font-size: 13px;

  @media #{$tablet} {
    font-size: 8px;
  }

  @media #{$mobile} {
    font-size: 8px;
  }
}

.review {
  background-color: $light-gray;
  width: 480px;
  margin: auto;

  @media #{$tablet} {
    width: 300px;
  }

  @media #{$mobile} {
    width: calc(min(350px, 100vw - 40px));
  }
}

.services {
  margin-top: 60px;
  justify-content: center;
  display: flex;
  justify-content: space-around;
  position: relative;

  @media #{$responsive} {
    margin-top: 20px;
  }
}

.serviceLabel {
  font-size: 18px;

  @media #{$responsive} {
    font-size: 14px;
  }
}

.comment {
  border: 1px solid #{$gray75}40;
  padding: 10px 20px;
  border-radius: 40px;

  @media #{$tablet} {
    padding: 5px 10px;
    font-size: 13px;
  }

  @media #{$mobile} {
    padding: 10px 20px;
    font-size: 13px;
  }
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 33.3%;
  max-width: 115px;

  @media #{$responsive} {
    padding: 0 5px;
  }
}

.serviceIcon {
  z-index: 1;
  width: 80px;
  height: 80px;

  @media #{$tablet} {
    width: 55px;
  }

  @media #{$mobile} {
    width: 55px;
  }
}

.lookingFor {
  font-size: 24px;

  @media #{$tablet} {
    font-size: 16px;
  }

  @media #{$mobile} {
    font-size: 16px;
  }
}

.rightPanel {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-left: 35px;

  @media #{$responsive} {
    padding-left: 10px;
  }
}
