@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  background-color: $light-gray;
  display: flex;
  flex-direction: column;
  padding: 60px 90px 40px;

  @media #{$tablet} {
    padding: 30px 45px 10px;
  }

  @media #{$mobile} {
    padding: 30px 30px 10px;
  }
}

.top {
  display: flex;
  justify-content: space-between;

  @media #{$mobile} {
    flex-direction: column;
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 1px solid $supplemental-variant-1;
  margin-top: 50px;

  @media #{$tablet} {
    align-items: flex-start;
  }

  @media #{$mobile} {
    align-items: flex-start;
    flex-direction: column-reverse;
    margin-top: 30px;
    gap: 10px;
  }
}

.bottomActions {
  align-items: center;
  display: flex;
  gap: 50px;

  @media #{$tablet} {
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
  }

  @media #{$mobile} {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
}

.logo {
  width: 330px;
  margin-bottom: 30px;

  @media #{$tablet} {
    width: 200px;
  }

  @media #{$mobile} {
    margin-bottom: 15px;
    width: 150px;
  }
}

.redirects {
  gap: 40px;
  display: flex;

  @media #{$mobile} {
    margin-top: 30px;
    gap: 40px;
    flex-direction: column;
  }
}

.redirectCategory {
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media #{$mobile} {
    gap: 15px;
  }
}

.redirectOptions {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media #{$mobile} {
    gap: 10px;
  }
}

.legalLabel {
  font-size: 12px !important;

  @media #{$responsive} {
    font-size: 10px !important;
  }
}

.copyright {
  font-size: 12px !important;

  @media #{$responsive} {
    font-size: 10px !important;
  }

  @media #{$mobile} {
    margin-top: 15px;
    font-size: 9px !important;
  }
}

.label {
  font-size: 12px !important;

  @media #{$responsive} {
    font-size: 10px !important;
  }
}
