@import "src/theme/media.scss";
@import "src/theme/colors.scss";

.container {
  padding: 90px 100px 40px;

  @media #{$tablet} {
    padding: 40px 25px;
  }

  @media #{$mobile} {
    padding: 30px 35px;
  }
}

.title {
  margin: 0 auto 25px auto;
  text-align: center;
  font-size: 40px;

  @media #{$mobile} {
    width: 250px !important;
    font-size: 24px;
    margin-bottom: 15px !important;
  }

  @media #{$tablet} {
    font-size: 32px;
  }
}

.subtitle {
  font-size: 24px;
  max-width: 700px !important;
  margin: 0 auto 60px auto;
  text-align: center;

  @media #{$mobile} {
    font-size: 14px;
    width: min(90%, 280px) !important;
    margin: 0 auto 40px auto;
  }

  @media #{$tablet} {
    width: 250px !important;
    font-size: 14px;
  }
}

.wrapper {
  background-color: $light-gray;
}

.content {
  display: flex;
  justify-content: space-between;

  @media #{$tablet} {
    justify-content: center;
    gap: 35px;
  }

  @media #{$mobile} {
    display: none;
  }
}

.cta {
  width: fit-content;
}

.mobileContent {
  display: none;

  @media #{$mobile} {
    margin: 0 auto;
    display: block;
  }
}

.network {
  margin-top: 60px;
  border-radius: 20px;
  border: 1px solid #{$gray75}60;
  align-items: center;
  gap: 60px;
  padding: 60px 40px;
  display: flex;
  flex-direction: column;

  @media #{$tablet} {
    margin: 50px auto 0;
    width: 675px;
    padding: 40px;
    gap: 40px;
  }

  @media #{$mobile} {
    margin: 30px auto 0;
    padding: 40px;
    gap: 30px;
    max-width: 350px;
    width: 100%;
  }
}

.offerings {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.networkTitle {
  letter-spacing: 0.28em;
  font-size: 19px !important;

  @media #{$tablet} {
    font-size: 15px !important;
  }

  @media #{$mobile} {
    text-align: center;
    font-size: 14px !important;
  }
}

.networkIcon {
  height: 100px;
  width: 100px;

  @media #{$responsive} {
    height: 60px;
    width: 60px;
  }
}

.networkOffering {
  flex: 1 0 21%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  @media #{$mobile} {
    flex: 1 0 42%;
  }

  & * {
    max-width: 150px;

    @media #{$responsive} {
      max-width: 110px;
      font-size: 14px;
    }
  }
}

.cta {
  @media #{$mobile} {
    padding: 15px 20px !important;
  }
}

.imageContainer {
  max-width: 622px;
  margin: 0 auto;
}

.chatFlowImage {
  width: 100%;
  height: auto;
}
