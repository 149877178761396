@import "src/theme/media.scss";

.container {
  padding: 0 50px;

  @media #{$responsive} {
    padding: 0;
  }
}

.content {
  padding: 45px 0;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  max-width: 100%;

  @media #{$mobile} {
    justify-content: center;
    padding: 45px 15px;
  }

  @media #{$tablet} {
    justify-content: center;
  }

  @media #{$responsive} {
    flex-wrap: wrap;
  }
}
