@import "src/theme/media.scss";
@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 65px 25px;
  align-items: center;

  @media #{$tablet} {
    padding: 45px 0;
  }

  @media #{$mobile} {
    padding: 40px 0;
  }
}

.title {
  font-size: 40px;

  @media #{$tablet} {
    font-size: 32px;
  }

  @media #{$mobile} {
    font-size: 27px;
    width: min(320px, 100%);
  }
}

.locations {
  align-items: flex-start;
  display: flex;
  gap: 25px;
  padding: 50px 0 25px !important;

  @media #{$tablet} {
    padding: 50px 10px 25px !important;
    gap: 10px;
  }
}

.imageCard {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;

  img {
    transform: scale(1);
    display: block;
    aspect-ratio: 3 / 4;
    object-fit: cover;
    width: 100%;
    transition: all 0.2s ease-out;
    filter: none;
  
    &:hover {
      filter: drop-shadow(0px 8px 24px rgba(149, 157, 165, 0.4));
    }
  }

  &:hover {
    img {
      transform: scale(1.1);
    }

    .cardText {
      height: 100%;

      &::before {
        height: 200%;
      }
    }
  }
}

.cardText {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px 15px;
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  text-align: center;
  transition: all 0.2s ease-out;

  & > * {
    position: relative;
    margin: 0 auto;
    color: white !important;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 20px);
    opacity: 0.7;
    background: linear-gradient(to top, #0d291c 0%, #0d291c00);
    transition: all 0.2s ease-out;
  }
}

.cardState {
  margin-top: 10px;
  font-size: 12px;
  opacity: 0.7;
}

.carrouselWrapper {
  width: 100%;
  padding: 30px 0 15px !important;
}

.carrousel {
  padding: 5px 0;
}

.mobileImage {
  border-radius: 10px;
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.cHeader {
  padding: 0 !important;
  background: transparent !important;
}

.city {
  font-size: 14px;
  padding-top: 15px !important;
}

.chevron {
  margin-left: 10px;
  transition: all 0.3s ease-in-out;
  fill: $light-primary-1;
}

.rotatedChevron {
  transform: rotate(180deg);
}
