@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.wrapper {
  background-color: $primary-variant-1;
  position: relative;
  width: 100%;
  height: 560px;

  @media #{$tablet} {
    padding-top: 25px;
    justify-content: flex-start;
    height: 635px;
  }

  @media #{$mobile} {
    padding-top: 25px;
    justify-content: flex-start;
    height: 615px;
  }
}

.leftShape {
  position: absolute;
  left: 0;
  top: 50%;
  width: 445px;
  transform: translate(-50%, -50%);
  fill: $secondary-variant-2;

  @media #{$tablet} {
    width: 350px;
  }

  @media #{$mobile} {
    width: 220px;
  }

  @media #{$responsive} {
    top: unset;
    bottom: 0;
    transform: translate(-35%, 35%);
  }
}

.rightShape {
  position: absolute;
  width: 445px;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  fill: $secondary-variant-1;

  @media #{$tablet} {
    width: 350px;
  }

  @media #{$mobile} {
    width: 220px;
  }

  @media #{$responsive} {
    top: unset;
    bottom: 0;
    transform: translate(35%, 35%);
  }
}

.background {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: 1;
}

.subtitle {
  @media #{$responsive} {
    width: 80% !important;
  }
}

.content {
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 45px;
  height: 100%;

  @media #{$responsive} {
    gap: 15px;
  }
}

.container {
  height: 100%;

  @media #{$responsive} {
    padding: 25px;
  }
}

.formWrapper {
  @media #{$tablet} {
    width: 80%;
  }

  @media #{$mobile} {
    width: 100%;
  }
}

.form {
  width: 700px;
  margin-bottom: 45px;

  @media #{$tablet} {
    margin-bottom: 0;
    width: 100%;
  }

  @media #{$mobile} {
    width: 100%;
  }
}

.submit {
  margin: 45px auto 0;

  @media #{$responsive} {
    width: 100%;
  }
}

.shape1 {
  position: absolute;
  width: 600px;
  height: 600px;
  fill: $accent !important;
  left: 0;
  bottom: 0;
  transform: translate(-35%) rotate(10deg);
}
